.container {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

/* nav style */
.nav .title {
  text-transform: capitalize;
  color: green;
  font-weight: 500;
}


.nav .searchIcon {
  color: green;
  position: absolute;
  right: 10px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.nav .searchIcon:active {
  background-color: #eee;
}

/* end nav style */



.list {
  display: flex;
  flex-direction: column;
  padding: 2px;
  height: 100%;
  overflow-y: scroll;
  opacity: 1;
}

.item {
  display: flex;
  gap: 10px;
  border-radius: 5px;
  padding: 10px;
}

.item:hover {
  background-color: #f6f6f6;
}

.item:active {
  background-color: #f1f1f1;
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px solid green; */
  box-sizing: border-box;
  background-color: #eeee;
}

.onlign {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 80px;
  border: 3px solid white;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.nac {
  display: flex;
  gap: 10px;
}

.fullName {
  font-weight: 500;
}

.age {
  color: #666;
}

.country {
  background-color: green;
  color: white;
  padding: 2px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px -3px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

.bio {
  font-size: 14px;
}

.languages {
  width: 100%;
  display: flex;
  /* justify-content: space-between;
  gap: 25%; */
}

.languages>* {
  width: 50%;
  text-align: center;
}

.languages .title {
  text-transform: uppercase;
  font-size: 12px;
  color: #888;
  text-align: center;
  margin-bottom: 5px;
}

.languages .content {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
}


.noUsers {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font: 16px;
  font-weight: 500;
}