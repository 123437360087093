.container {
  width: var(--max-width);

  background-color: #fefefe;
  margin: auto;
  height: 100svh !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.listMessages {
  height: 100%;
}

@media screen and (max-width: 400px) {
  .container {
    position: fixed;
    width: 100%;
    height: 100svh !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }
}