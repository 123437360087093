.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 0px 5px -3px;
  padding: 10px;
}


.title {
  font-size: 16px;
  font-weight: 500;
}

.button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.button:active {
  background-color: #eee;
}