.container {
  position: fixed;
  max-width: var(--max-width);
  margin: auto;
  z-index: 10;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.29);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .content {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  max-width: 85vw;
} */