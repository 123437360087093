.listMessages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;

  overflow-y: scroll;
  padding: 10px;
}

.messageItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 90%;
  width: fit-content;
  background-color: #eee;
  min-width: 50px;
}

.messageText {
  font-size: 17px;
  word-break: break-all;
}

.messageTime {
  margin-left: auto;
  width: fit-content;
  opacity: .8;
  font-size: 15px;
}

.mine {
  margin-left: auto;
  background-color: rgb(0, 107, 0);
  color: white;
}