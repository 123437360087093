.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  margin: 5px 8px;
  padding: 10px;
  box-shadow: 0px 0px 5px -4px;
  /* overflow: hidden; */
  box-sizing: border-box;
}

.item:first-child {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border-radius: 5px;
  margin: 5px 8px;
  padding: 10px;
  box-shadow: 0px 0px 5px -4px;
}

.mainMenu {
  color: green;
  margin-left: auto;
  cursor: pointer;

}



.mainMenu>* {
  font-size: 28px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -2px grey;
}

.mainMenu>*:active {
  background-color: #eee;
}

.imageContainer {
  margin: auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.imageContainer>.image {
  background-color: #efefef;
  overflow: hidden;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid green;
}


.imageContainer>.edit {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer>.edit * {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -5px;
  font-size: 27px;
  color: green;
}

.imageContainer>.edit>*:active {
  background-color: #eee;
}

.fullName {
  margin: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.item .fullName>.content {
  font-size: 20px;
  text-transform: none;
}

.edit {
  color: green;
  cursor: pointer;
}

.edit:active {
  border-radius: 5px;
  background-color: #eee;
}

.item .title {
  color: #777;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 10px;
}

.item>.edit {
  position: absolute;
  right: 10px;
}

.item .list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item .list>* {
  font-weight: 500;
  margin-left: 10px;
  text-transform: capitalize;
}

.item .bio {
  margin-inline: 10px;
}

.item .content {
  font-weight: 500;
  text-transform: capitalize;
  margin-inline: 10px;
}



/* scroll style */