.container {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}


.list {
  height: inherit;
  overflow-y: scroll;
}

/* nav style */
.nav .title {
  text-transform: capitalize;

  color: green;
  font-weight: 500;
}


/* .nav .searchIcon {
  color: green;
  position: absolute;
  right: 10px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.nav .searchIcon:active {
  background-color: #eee;
}

*/
/* end nav style */


/* .list {} */

.item {
  display: flex;
  gap: 10px;
  padding: 10px;

}

.item:hover {
  background-color: var(--mcolor-dark-white);
  border-radius: 5px;
}


.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eee;
}

.onlin {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: green;
  border: 2px solid white;
  position: absolute;
  right: 1px;
  top: 45px;
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}



.nm {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-block: 5px;
}

.fullName {
  font-weight: 500;
}

.lastMessage {
  color: #666;
  font-size: 14px;
}

.time,
.unreadMessage {
  font-size: 14px;
}

.unreadMessage {
  background-color: rgb(0, 175, 0);
  margin-left: auto;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50px;
}