.container {
  background-color: var(--mcolor-white);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.header {
  display: flex;
  justify-content: space-between;
}

.title {
  text-transform: capitalize;
  font-weight: 500;
}

.hide {
  background-color: white;
  border: 2px solid rgb(195, 0, 0);
  padding: 2px;
  color: rgb(195, 0, 0);
  border-radius: 4px;
  cursor: pointer;
}

.hide:hover {
  border-color: red;
  color: red;
}

.searchBox {
  display: flex;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
}

.input,
.button {
  border: 1px solid var(--mcolor-light-black);
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

.button {
  cursor: pointer;
}

.input {
  width: 100%;
}