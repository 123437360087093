.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  color: gray;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.input {
  padding: 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid gray;
  font-size: 18px;
}

.input:valid[focused=true] {
  border: 1px solid green;
}

.input:invalid[focused=true] {
  border: 1px solid red;
}

.span {
  color: red;
  display: none;
}

.input:invalid[focused=true]~.span {
  display: block;
}

.passwordVisibilityIcon {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}