.container {
  background-color: var(--mcolor-grey);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;

}

.item {
  background-color: var(--mcolor-white);
  border-radius: 10px;
  padding: 10px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px -5px black;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header>* {
  padding: 2px;
  border-radius: 2px;
  cursor: pointer;
}

.header>*:active {
  background-color: var(--mcolor-grey);
}

.imageContainer {
  box-sizing: border-box;
  width: 200px;
}

.image {
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--mcolor-dark-white);
  /* border: 2px solid green; */
  box-sizing: border-box;
  margin-bottom: 10px;
}

.onlign {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 20px;
  border: 5px solid white;
  position: absolute;
  top: 158px;
  left: 158px;
}

.fullName .content {
  font-size: 17px;
  font-weight: 500;
}

.startChat {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 10px;
  padding: 5px;
  background-color: rgb(0, 159, 0);
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.startChat:active {
  opacity: .8;
}

.icon {
  position: relative;
  top: 4px;
}

/**
* set first item align items center
*/
.item:first-of-type {
  align-items: center;
}

.title {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: var(--mcolor-light-black);
  margin-bottom: 5px;
}

.content {
  font-size: 15px;
  font-weight: 500;
}

/* test personal info style */

.personalInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.personalInfo>hr {
  width: 85%;
  margin: auto;
  background-color: var(--mcolor-dark-grey);
  border: none;
  padding: .5px;
  border-radius: 2px;
}

.personalInfo>div:first-of-type {
  display: flex;
}

.personalInfo>div:first-of-type>* {
  width: 100%;
}