.container {

  box-shadow: 0px 0px 5px -3px black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: white;
}