.container {
  z-index: 10;
  width: var(--max-width);
  position: fixed;
  left: 50%;
  margin-left: calc(0px - (var(--max-width) / 2));
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.212);
}

@media screen and (max-width: 400px) {
  .container {
    position: fixed;
    top: 0px;
    height: 100%;
  }
}

.square {

  gap: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: green;
  font-weight: bold;
}

.loading {
  padding: 15px;
  border: 6px solid green;
  border-radius: 50%;
  border-bottom: 6px solid white;
  animation: 1.1s spin linear infinite;


}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}