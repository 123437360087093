.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  height: 100%;
}


.search {
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  font-size: 17px;
}


.list {
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow-y: scroll;
}

.item {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
}

.item:hover {
  background-color: #eeee;
}

.selected {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: #dddd;
}