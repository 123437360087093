.inputMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  box-sizing: border-box;
}

.input {
  width: 100%;
  padding: 10px 10px;
  font-size: 17px;
  height: 100%;
  outline: none;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-sizing: border-box;

  resize: none;
  /* max-lines: 2;/ */

}

.button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  transform: scale(1.5);
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;

}

.button:active {
  background-color: #f1f1f1;
  color: rgba(0, 128, 0, 0.826);
}