.button {
  background-color: green;
  font-size: 18px;
  text-transform: capitalize;
  border: none;
  padding: 10px 5px;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -5px green;
  cursor: pointer;

}

.button:hover {
  box-shadow: 0px 0px 10px -5px green;
}

.button:active {
  box-shadow: 0px 0px 2px 0px green;
}