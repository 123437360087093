.container {
  background-color: #eeeeee;
  max-width: 400px;
  height: 100svh !important;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  overflow-y: hidden;
  width: inherit;
  opacity: 1;
}

.nav {
  height: fit-content;
  opacity: 1;
}

@media screen and (max-width: 400px) {
  .container {
    position: fixed;
    width: 100%;
    height: 100svh !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }
}



/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}