.para{
  color: black;
  font-size: 17px;
  margin-block: 10px
  ;
}

.para > a{
  color: green;
  text-decoration: underline;
}
