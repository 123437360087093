.container {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px;
}

.title {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.body {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.776);
}

.button {
  background-color: #d6d6d6;
  width: fit-content;
  padding: 4px 16px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 1px 5px -3px black;
  margin-left: auto;
  text-transform: uppercase;
}

.button:active {
  background-color: #dddddd;
  color: black;
  user-select: none;
}