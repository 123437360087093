:root {
  --max-width: 400px;

  --mcolor-grey: #eee;
  --mcolor-dark-grey: #ddd;

  --mcolor-white: #fff;
  --mcolor-dark-white: #f6f6f6;

  /* used for personal info title */
  --mcolor-light-black: #999;
}

* {
  padding: 0px;
  position: relative;
  margin: 0px;
  list-style: none;
  text-decoration: none;
}

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 100svh;
  /* background-color: red; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
}

/**
*scroll bar
*/

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}