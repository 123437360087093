.container {
  max-width: 400px;
  min-width: 200px;
  width: 85%;
  background-color: white;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}

.input {
  /* background-color: ; */
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  outline: none;
  font-size: 16px;
  border-radius: 8px;
  border: .5px solid;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttons>* {
  padding: 4px 8px;
  border-radius: 4px;
  border: .5px solid;
  cursor: pointer;
  box-shadow: 0px 0px 6px -3px black;
}

.yes {
  background-color: green;
  color: white;
}

.buttons>*:active {
  opacity: .6;
}


.error {
  color: red;
  font-size: 14px;
  position: relative;
  top: -10px;
}