.container {
  background-color: white;
  max-width: calc(var(--max-width) * (90/100));
  width: 85%;
  border-radius: 10px;
}

.menu {

  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding: 5px;
}

.menu>* {
  padding: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.close {
  color: white;
  background-color: darkred;
  padding: 4px;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 800;
}

.close:hover {
  background-color: red;
}

.close:active {
  opacity: .8;
}

.hidden {
  opacity: 0;
}

.title {
  font-weight: 500;
  font-size: large;
}


.item {
  text-transform: capitalize;
  font-size: large;
  border-radius: 4px;
  cursor: pointer;
}

.item::after {
  /* content: ""; */
  background-color: black;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding-block: 1px;
}

.item:hover {
  background-color: #eeee;
}