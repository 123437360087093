.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline: auto;
  background-color: #fbfbfb;
  width: 350px;
  height: fit-content;
  padding: 10px;
  margin-block: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -3px black;
}

@media screen and (max-width: 480px) {
  .container {
    width: 85%;
  }
}