.container {
  display: flex;
  justify-content: space-evenly;
  background-color: green;
  color: white;
  user-select: none;

}

.item,
.selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
  opacity: .5;
  width: 100%;
}

.item:hover {
  opacity: .8;
}

.selected {
  opacity: 1;
}

.title {
  text-transform: capitalize;
  position: relative;
  top: -5px;
}